<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleCancel"
    @close="handleClose"
    okText="Valider et imprimer"
    @ok="handleOk"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :layout="formLayout"
      id="rapport_code_form"
      name="rapport_code_form"
      class="adherent_form"
      :form="form"
    >
      <a-row>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Code">
            <a-input
              v-decorator="[
                'code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Le code est obligatoire',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>
<script>
export default {
  name: "AdherentsRapportCode",
  mounted() {
    this.fillTheForm();
  },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      },
      form: this.$form.createForm(this),
    };
  },
  props: {
    visible: { type: Boolean, default: () => false },
    user: { type: Object, required: true },
    rapport: { type: Object, required: true },
    rapport_code: { type: String },
  },
  computed: {
    title() {
      return `${this.get(this.user, "full_name")} (${this.get(this.rapport, "nom_affichage")})`;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("submit");
    },
    fillTheForm() {
      this.$nextTick(() => this.form.setFieldsValue({code: this.rapport_code}));
    },
  },
};
</script>
