<template>
  <a-cascader :field-names="{
    label: 'nom_affichage',
    value: 'id',
    children: 'exercices'
  }" :options="fileredRapports" :placeholder="placeholder"
    @change="handleDocumentsPrinting" />
</template>
<script>

import { mapState } from "vuex";
import _indexOf from "lodash-es/indexOf";
import _isEmpty from "lodash-es/isEmpty";
import _find from "lodash-es/find";
import _filter from "lodash-es/filter";


export default {
  name: "DocumentPrintingList",
  props: { user: Object, window: String, placeholder: String },
  created() {
    this.$store.cache.dispatch("fetchRapports");
  },
  methods: {
    handleDocumentsPrinting(selectedItem) {
      if (selectedItem[0] !== undefined)
        this.$emit("handle_documents_printing", selectedItem);
    },
    etatCanPrint(whoCanPrint) {
      if (_isEmpty(this.user) && _isEmpty(whoCanPrint)) {
        return true;
      }
      return this.user && _find(whoCanPrint, { 'value': this.get(this, "user.etat") })
        ? true
        : false;
    },
    displayInThisWindows(placeToDisplay) {
      return this.get(this, 'window') === placeToDisplay;
    },
    statuProCanPrint(whoCanPrint) {
      if (_isEmpty(this.user) && _isEmpty(whoCanPrint)) {
        return true;
      }
      return this.user && _indexOf(whoCanPrint, this.statutProfessionnel) < 0
        ? false
        : true;
    },
  },
  computed: {
    ...mapState({
      rapports: (state) => state.rapports.rapports,
    }),
    fileredRapports() {
      return _filter(
        this.rapports,
        (r) =>
          this.displayInThisWindows(r.place) &&
          this.etatCanPrint(r.etats) &&
          this.statuProCanPrint(r.statut_professionnels)
      );
    },
    isEnAttenteOrAccepted() {
      if (this.isSelectedAdherantAccepted) {
        return true;
      }
      if (this.isSelectedAdherantEnAttente) {
        return true;
      }
      return false;
    },
    isSelectedAdherantAccepted() {
      return this.isUserAccepter(this.get(this, "user.etat"));
    },
    isSelectedAdherantEnAttente() {
      return this.isUserEnAttente(this.get(this, "user.etat"));
    },
    isSelectedAdherantRetraite() {
      return this.isUserStatuProfessRetraiter(this.statutProfessionnel);
    },
    statutProfessionnel() {
      return this.get(this, "user.etat_activites[0].statut_professionnel");
    },
  },
};
</script>
