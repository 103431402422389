<template>
  <div>
    <h1 style="margin-left: 0%; margin-bottom: 4%">Etat des inscrits</h1>
    <ComposeMail ref="composeMail" :visible="emailModalVisible" :is-admin="true" :reply-to="replyTo"
      :isDestinationsSelectDisabled="true" :key="newMailComponentKey" @cancel="hideEmailModal" @create="handleCreate"
      @upload_success="handleUploadedFile($event)" />
    <AdherentsRapportCode :user="selectedUser" :visible="rapportCodeVisible" 
      :rapport="selectedRapport" :rapport_code="selectedRapportCode" ref="adhrentsRapportCode"
      @submit="handleRapportCodeSubmit" @close="handleRapportCodeClose" />
    <a-spin :spinning="downloading">
      <AdherentDecision :user="selectedUser" :visible="decisionModalVisible" :processing="decisionProcessing"
        @close="handleDecisionFormClose" @submit="handleDecisionFormSubmit" :key="newDecisionComponentKey"
        ref="decisionForm" />
      <a-row type="flex" justify="space-between">
        <a-col :span="12">
          <a-button class="editable-add-btn" type="primary" @click="addNewAdherent">Ajouter inscrit</a-button>
        </a-col>
        <a-col :span="12">
          <a-row type="flex" justify="end">
            <a-col>
              <DocumentPrintingList :user="selectedUser" :key="documentPrintingKey" :window="windowsName"
                placeholder="Imprimer document" @handle_documents_printing="handleDocumentsPrinting($event)" />
            </a-col>
            <a-col class="margin_left">
              <a-button class="editable-add-btn" type="primary" @click="printAllList">Imprimer liste affichée</a-button>
            </a-col>
          </a-row>    
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-between">
        <a-col :xs="22" :sm="10" :md="7" :lg="7" class="mrgin_btm">
          <WilayasList :data="wilayas" :selected-value="Number(selected_wilaya_value)"
            @wilaya_change="handleWilayaFilterChange($event)" />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="7" class="mrgin_btm">
          <EtatInscriptionList :localEtats="etatsAdherents" :selected-value="Number(selected_etat_value)"
            @etat_inscription_change="handleEtatInscriptionChange($event)" />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="7" class="mrgin_btm">
          <EtatSecteurActiviteList :localEtats="secteurs" :selected-value="Number(selected_secteur_value)"
            @etat_secteur_activite_change="handleSecteurActiviteChange($event)" />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="7" class="mrgin_btm">
          <EtatPaiementList :selected-value="[Number(selected_paiement_value), selected_exercice_value]" :placeholder="'Etat paiement'"
            :exercices="getExercices()" @etat_paiement_change="handlePaiementFilterChange($event)" />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="7" class="mrgin_btm">
          <EtatProfessionalList :etatsPossibles="statutsProfessionels" :selected-value="Number(selected_etat_professionnel_value)"
          @etat_professionnel_change="handleEtatProfessionnelFilterChange($event)" />
        </a-col>
        <a-col :xs="22" :sm="10" :md="7" :lg="7" class="mrgin_btm txt-end">
          <div style="display: flex; align-items: center;">
            <a-input v-model="q" class="search_input" placeholder="Rechercher" @keyup.enter="search" />
            <a-button type="primary" @click="search">
              <a-icon type="search" />
            </a-button>
          </div>
        </a-col>
      </a-row>

      <a-spin :spinning="usersLoading">
        <a-table bordered v-if="adherents.length" :dataSource="adherents" :columns="columns" :rowKey="(record) => {
          return record.id;
        }
          " :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            hideDefaultSelections: true,
            onSelect: onSelect,
          }" :pagination="false" @change="handleSorterChange">
          <template slot="etat" slot-scope="text, record">
            <EtatInscriptionIndicator :etat-inscription="record.etat" />
          </template>
          <template slot="statut_prof" slot-scope="text, record">
            <StatuProfessionalIndicator :statut-professionnel="getStatuProfessionnel(record.etat_activites)
              " />
          </template>
          <template slot="secteur_activite" slot-scope="text, record">
            {{ get(record, "etat_activites[0].secteur.nom_fr") }}
          </template>
          <template slot="wilaya_professionnelle" slot-scope="text, record">
            {{ get(record, "wilaya_professionnelle.nom_fr") }}
          </template>

          <template slot="cotisation" slot-scope="text, record">
            <EtatPaiementIndicator :etat="record.etat" :balance="get(record, 'etat_paiement.balance', 1)" />
          </template>
          <template class="table-operation" slot="operation" slot-scope="text, record">
            <a-tooltip placement="top">
              <template slot="title">
                <span>État activités</span>
              </template>
              <a-icon type="clock-circle" @click="goToAdherentEtatActivite(record.id)" class="action_icons"
                theme="twoTone" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Cotisation</span>
              </template>
              <a-icon type="dollar" @click="goToAdherentCotisation(record.id)" class="action_icons" theme="twoTone"
                :twoToneColor="get(record, 'etat_paiement.balance', 1) >= 0
                  ? '#52c41a'
                  : '#eb2f96'
                  " />
            </a-tooltip>
            <a-popconfirm v-if="adherents.length" title="Etes-vous sûr de vouloir supprimer cette ligne ?"
              @confirm="() => onDelete(record.id)">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>Suppression</span>
                </template>
                <a-icon type="delete" class="action_icons" theme="twoTone" twoToneColor="#eb2f96" />
              </a-tooltip>
            </a-popconfirm>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Edition</span>
              </template>
              <a-icon type="edit" v-on:click="handleEdit(record.id)" class="action_icons" theme="twoTone"
                twoToneColor="#52c41a" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Décision</span>
              </template>
              <img class="decision" style="width: auto; height: auto" @click="showDecisionForm(record)"
                src="https://img.icons8.com/dotty/22/000000/test-partial-passed.png" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Demandes</span>
              </template>
              <a-icon type="form" class="action_icons" @click="showUserDemandes(record)" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Envoyer mail</span>
              </template>
              <a-icon type="message" v-on:click="sendEmail(record)" class="action_icons" theme="twoTone" />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>Demande Attestations</span>
              </template>
              <a-icon type="highlight" @click="showUserAttestations(record)" class="action_icons" />
            </a-tooltip>
          </template>
          <template slot="nom_prenom" slot-scope="text, record">
            <span v-bind:class="{ black: isUserEnAttente(record) }">
              <a-avatar v-if="record.profile_picture" slot="avatar" :src="record.profile_picture_url" />
              <a-avatar v-else>{{ record.nom_fr[0] }}</a-avatar>
              <a-badge status="processing" v-if="isUserEnAttente(record)" />
              <span v-if="isFemme(record.sexe) && hasConj(record.nom_conj)">
                {{ record.nom_conj }} <b>née</b> {{ record.full_name }}
              </span>
              <span v-else>
                {{ record.full_name }}
              </span>
            </span>
          </template>
          <!-- _________________________START___MATRICULE-->
          <template slot="matricule" slot-scope="text, record">
            <span v-bind:class="{ black: isUserEnAttente(record) }">
              <a-badge status="processing" v-if="isUserEnAttente(record)" />
              {{ record.matricule }}
            </span>
          </template>
          <!-- _________________________END___MATRICULE-->
          <!-- START Child Row -->
          <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
            <UserDetails :user="record" />
          </div>
          <!-- END Child Row -->
        </a-table>
        <EmptyResult v-else />
        <a-pagination v-if="adherents.length" style="margin-top: 1%" @change="onPaginationChange"
          @showSizeChange="onPageSizeChange" :current="pagination.current" :total="total"
          :showTotal="(total) => `Total : ${total}`" :pageSize="pagination.pageSize" :showSizeChanger="true" />
      </a-spin>
    </a-spin>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
// import SearchComponent from "@/components/common/Search";
import AdherentDecision from "./AdherentsDecision";
import AdherentsRapportCode from "./AdherentsRapportCode";
import constants from "@/const/const";
import ComposeMail from "@/components/common/messagerie/NewMail";
import EmptyResult from "@/components/common/EmptyResult";
// import EtatDossierList from "@/components/common/EtatDossierList";
import EtatInscriptionList from "@/components/common/EtatInscriptionList";
import WilayasList from "@/components/common/WilayasList";
import EtatPaiementList from "@/components/common/EtatPaiementList";
import EtatProfessionalList from "@/components/common/EtatProfessionalList";
import StatuProfessionalIndicator from "@/components/common/StatuProfessionalIndicator";
import EtatPaiementIndicator from "@/components/common/EtatPaiementIndicator";
import EtatInscriptionIndicator from "@/components/common/EtatInscriptionIndicator";
import EtatSecteurActiviteList from "@/components/common/EtatSecteurActiviteList";
import DocumentPrintingList from "@/components/common/DocumentPrintingList";
import UserDetails from "./UserDetails";
import _find from "lodash-es/find";

const ADHERENTS_URL = `/admin/adherents/edit/`;
const CREATE_USER_URL = "/admin/adherents/create";


export default {
  name: "AdherentsList",
  mounted() {
    this.fetchData();
    if (this.adherents.length > 0)
      return;
    this.getUnreadMessagesCount();
    this.getNewDemandeAttestationCount();
    this.getNewPaiementsCount();
    this.getNewDemandeOuverturesCount();
    this.getNewUsersCount();
    this.getHelperData();
  },
  components: {
    AdherentDecision,
    AdherentsRapportCode,
    ComposeMail,
    EmptyResult,
    EtatInscriptionList,
    EtatPaiementList,
    EtatProfessionalList,
    StatuProfessionalIndicator,
    EtatInscriptionIndicator,
    UserDetails,
    EtatPaiementIndicator,
    EtatSecteurActiviteList,
    DocumentPrintingList,
    WilayasList,
  },
  data() {
    return {
      selectedUser: null,
      filetredDataSource: [],
      selected_sorting_value: "asc",
      index: 0,
      usersLoading: false,
      downloading: false,
      emailModalVisible: false,
      replyTo: null,
      replyToId: null,
      documentPrintingKey: 0,
      newMailComponentKey: 0,
      newDecisionComponentKey: 0,
      windowsName: "etat inscrits",
      listeAdherentWindowsName: "liste adherents",
      decisionModalVisible: false,
      decisionProcessing: false,
      newEntriesTimer: 1000 * 30,
      selectedRowKeys: [],
      rapportCodeVisible: false,
      selectedRapport: undefined,
      selectedRapportCode: undefined,
      selectedPrintingValue: undefined,
      options: [
        {
          value: "15",
          label: "Zhejiang",
        },
        {
          value: "d",
          label: "Jiangsu",
          children: [
            {
              value: "e",
              label: "Nanjing",
              children: [
                {
                  value: "f",
                  label: "Zhong Hua Men",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    columns() {
      const columns = [
        {
          key: "nom_fr",
          title: "Nom & Prénom",
          scopedSlots: { customRender: "nom_prenom" },
        },
        {
          title: "Secteur activité",
          scopedSlots: { customRender: "secteur_activite" },
        },
        {
          title: "Wilaya Pro.",
          scopedSlots: { customRender: "wilaya_professionnelle" },
        },
        {
          title: "Etat",
          scopedSlots: { customRender: "etat" },
        },
        {
          title: "Statut professionnel",
          scopedSlots: { customRender: "statut_prof" },
        },
        {
          title: "Paiement",
          scopedSlots: { customRender: "cotisation" },
        },
        {
          title: "Actions",
          scopedSlots: { customRender: "operation" },
        },
      ];
      return columns;
    },
    q: {
      get() {
        return this.selected_search_value;
      },
      set(v) {
        this.setSelectedSearchValue(v);
      },
    },
    ...mapGetters({
      selected_etat_value: "getSelectedEtatValue",
      selected_paiement_value: "getSelectedPaiementValue",
      selected_secteur_value: "getSelectedSecteurValue",
      selected_etat_professionnel_value: "getSelectedEtatProfessionnelValue",
      selected_exercice_value: "getSelectedExerciceValue",
      selected_wilaya_value: "getSelectedWilayaValue",
      selected_search_value: "getSelectedSearchValue",
    }),
    ...mapState({
      adherents: (state) => state.adherents.adherents,
      total: (state) => state.adherents.pagination.total,
      pagination: (state) => state.adherents.pagination,
      etatsAdherents: (state) => state.adherents.etatsAdherents,
      wilayas: (state) => state.wilayas.organisation_wilayas,
      secteurs: (state) => state.secteurs.secteurs,
      organisation: (state) => state.organisation.organisation,
      statutsProfessionels: (state) => state.statuts.statuts,
      rapports: (state) => state.rapports.rapports,
    }),
  },
  methods: {
    ...mapMutations({
      setSelectedEtatValue: "setSelectedEtatValue",
      setSelectedPaiementValue: "setSelectedPaiementValue",
      setSelectedSecteurValue: "setSelectedSecteurValue",
      setSelectedWilayaValue: "setSelectedWilayaValue",
      setSelectedEtatProfessionnelValue: "setSelectedEtatProfessionnelValue",
      setSelectedExerciceValue: "setSelectedExerciceValue",
      setSelectedSearchValue: "setSelectedSearchValue",
    }),
    showUserDemandes(user) {
      this.$router.push(`adherents/demandes/edit/${user.id}`);
    },
    showUserAttestations(user) {
      this.$router.push(`adherents/attestations/${user.id}`);
    },
    handleWilayaFilterChange(selectedItem) {
      this.setSelectedWilayaValue(selectedItem);
    },
    handleSecteurActiviteChange(selectedItem) {
      this.setSelectedSecteurValue(selectedItem);
    },
    handleEtatProfessionnelFilterChange(selectedItem) {
      this.setSelectedEtatProfessionnelValue(selectedItem);
    },
    handleSorterChange(pagination, filters, sorter) {
      this.setNameSorter(sorter.order);
      this.resetPagination();
      this.fetchData();
    },
    getStatuProfessionnel(etatActivites) {
      return this.get(etatActivites, "[0].statut_professionnel");
    },
    getSecteurActvite(etatActivites) {
      return this.get(etatActivites, "[0].secteur_activite");
    },
    fetchData() {
      this.startUsersLoading();
      this.getAdditioanlData(this.getParameters()).finally(() =>
        this.stopUsersLoading()
      );
    },
    search() {
      this.resetPagination();
      this.fetchData();
    },
    onPaginationChange(pagination) {
      this.setPagination({ ...this.pagination, current: pagination });
      this.fetchData();
    },
    onPageSizeChange(current, size) {
      this.setPagination({ ...this.pagination, pageSize: size });
      this.fetchData();
    },
    getParameters() {
      return {
        etat: this.selected_etat_value,
        paiement: this.selected_paiement_value,
        exercice: this.selected_exercice_value,
        wilaya: this.selected_wilaya_value,
        secteur_activite: this.selected_secteur_value,
        etat_professionnel: this.selected_etat_professionnel_value,
        sort_name: this.selected_sorting_value,
        q: this.q,
        per_page: this.pagination.pageSize,
        page: this.pagination.current,
      };
    },
    getHelperData() {
      this.$store.cache.dispatch("fetchExercices");
      this.$store.cache.dispatch("fetchWilayas");
      this.$store.cache.dispatch("fetchOrganisation");
      this.$store.cache.dispatch("fetchOrganisationWilayas");
      this.$store.cache.dispatch("fetchSpecialites");
      this.$store.cache.dispatch("fetchCommunes");
      this.$store.cache.dispatch("fetchDiplomes");
      this.$store.cache.dispatch("fetchSecteurs");
    },
    handleUploadedFile($event) {
      this.addAttachementFile($event);
    },
    handleCreate() {
      const form = this.$refs.composeMail.form;
      let that = this;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        let email = {};
        email.destination = [];
        email.attachements = that.getAttachementFiles();
        email.subject = values.subject;
        email.message = values.message;
        email.destination.push(this.replyToId);
        this.send(email)
          .then(() => {
            that.$_showSuccessMessage(`${constants.MESSAGE_SENT}`, 5);
            this.hideEmailModal();
            that.replyToId = null;
            form.resetFields();
          })
          .catch(() => {
            that.$_throwAnError(`${constants.MESSAGE_SENT_FAIL}`, 5);
          });
      });
    },
    setNameSorter(order) {
      this.selected_sorting_value = order === "ascend" ? "asc" : "desc";
    },
    sendEmail(adherant) {
      this.replyTo = adherant.email;
      this.replyToId = adherant.id;
      this.genererateNewPaiementEditorKey();
      this.showEmailModal();
    },
    genererateNewPaiementEditorKey() {
      this.newMailComponentKey = this.getRandomInt();
    },
    genererateNewDecisionEditorKey() {
      this.newDecisionComponentKey = this.getRandomInt();
    },
    genererateDocumentPrintingKey() {
      this.documentPrintingKey = this.getRandomInt();
    },
    onSelectChange(selectedRowKeys) {
      if (selectedRowKeys.length > 1) {
        this.$_throwAnError(
          " Vous devez d'abord désélectionner l'utilisateur  "
        );
        return;
      }
      this.selectedRowKeys = selectedRowKeys;
    },
    onSelect(record, selected) {
      if (selected) {
        this.selectedUser = record;
        return;
      }
      this.selectedUser = null;
    },
    addNewAdherent() {
      this.clearSelectedAdherent();
      this.$router.push(CREATE_USER_URL);
    },
    printAllList() {
      this.startUsersLoading();
      this.fetchAllSelectedIds(this.getParameters())
        .then(() => {
          this.printShownList({
            ids: this.getAllSelectedIds(),
            selected_paiement_value: this.selected_paiement_value,
            exercice: this.selected_exercice_value,
            organisation: this.organisation.id,
          });
        }).catch(() => this.$_throwAnError())
        .finally(() => this.stopUsersLoading());
    },
    handleDecisionFormSubmit() {
      this.startDecisionFormProcessing();
      this.$refs["decisionForm"].form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (this.get(values, "dt_comission")) {
            values["dt_comission"] = this.formatDate(
              values["dt_comission"],
              "YYYY-MM-DD"
            );
          }
          this.updateEtatConfirmation({
            id: this.selectedUser.id,
            ...values,
          })
            .then(() => {
              this.handleDecisionFormClose();
              this.resetDecisionFormFields();
              this.clearDecisionFormSelectedAdherent();
              this.fetchData();
            })
            .catch(() => {
              this.stopDecisionProcessing();
            });
        } else {
          this.stopDecisionProcessing();
        }
      });
    },
    hideEmailModal() {
      this.emailModalVisible = false;
    },
    showEmailModal() {
      this.emailModalVisible = true;
    },
    handleDecisionFormClose() {
      this.closeDecisionModal();
      this.stopDecisionProcessing();
    },
    handleRapportCodeSubmit() {
      this.setCodeForAdherant({
        rapport: this.selectedRapport,
        adherent: _find(this.adherents, { id: this.selectedRowKeys[0] }),
        new_code: this.$refs["adhrentsRapportCode"].form.getFieldValue('code'),
      }).then(() => {
        this.doPrint(this.selectedPrintingValue);
        this.handleRapportCodeClose();
        this.fetchData();
      });
    },
    handleRapportCodeClose() {
      this.$refs["adhrentsRapportCode"].form.resetFields();
      this.rapportCodeVisible = false;
      this.selectedRapport = undefined;
      this.selectedRapportCode = undefined;
    },
    showDecisionForm(user) {
      if (this.isUserNonSoumis(user)) {
        this.$_throwAnError("L'adhérent n'a pas encore soumis son dossier !");
        return;
      }
      this.genererateNewDecisionEditorKey();
      this.setDecisionFormSelectedAdherent(user);
      this.openDecisionModal();
    },
    stopDecisionProcessing() {
      this.decisionProcessing = false;
    },
    startDecisionFormProcessing() {
      this.decisionProcessing = true;
    },
    clearDecisionFormSelectedAdherent() {
      this.selectedUser = null;
    },
    setDecisionFormSelectedAdherent(user) {
      this.selectedUser = user;
    },
    resetDecisionFormFields() {
      this.$refs["decisionForm"].form.resetFields();
    },
    goToAdherentCotisation(userId) {
      this.$router.push(`adherents/cotisation/edit/${userId}`);
    },
    goToAdherentEtatActivite(userId) {
      this.$router.push(`adherents/etat-activites/edit/${userId}`);
    },
    handleEtatInscriptionChange(selectedEtat) {
      this.setSelectedEtatValue(selectedEtat);
    },
    handlePaiementFilterChange(selectedItem) {
      this.setSelectedPaiementValue(selectedItem.value);
      this.setSelectedExerciceValue(selectedItem.exercice);
    },

    handleDocumentsPrinting(value) {
      if (!value) return;
      
      const rapport = _find(this.rapports, {id: value[0]});
      const adherant = _find(this.adherents, {id: this.selectedRowKeys[0]})
      
      if (!!rapport && rapport.needs_code && !!adherant) {
        const rapport_code = _find(adherant.current_rapport_codes, {rapport_id: rapport.id})
        if (!rapport_code) {
          this.selectedRapport = rapport;
          this.selectedRapportCode = rapport_code;
          this.rapportCodeVisible = true;
          this.selectedPrintingValue = value;
          return;
        }
      }
      this.doPrint(value);
    },
    doPrint(value) {
      if (!value) return;
      
      let params = this.selectedRowKeys[0];
      this.startUsersLoading();
      this.callPdfGenerator({
        id: params ? params : 1,
        type: value[0],
        exercice: value[1],
        organisation: this.organisation.id,
      })
        .catch(() => this.$_throwAnError())
        .finally(() => {
          this.stopUsersLoading();
          this.selectedPrintingValue = undefined;
        });
    },
    openDecisionModal() {
      this.decisionModalVisible = true;
    },
    closeDecisionModal() {
      this.decisionModalVisible = false;
    },
    startUsersLoading() {
      this.usersLoading = true;
    },
    stopUsersLoading() {
      this.usersLoading = false;
    },
    onDelete(key) {
      this.remove(key).then(() => this.fetchData());
    },
    handleEdit(id) {
      this.$router.push(`${ADHERENTS_URL}${id}`);
    },
    ...mapMutations({
      clearSelectedAdherent: "clearSelectedAdherent",
      setPagination: "setPagination",
      resetPagination: "setDefaultPagination",
      addAttachementFile: "addAttachementsFileId",
    }),
    ...mapGetters({
      getAttachementFiles: "getAttachementsIds",
      getExercices: "getExercices",
      getAllSelectedIds: "getIds",
    }),
    ...mapActions({
      getAdditioanlData: "fetchAdminAdherents",
      remove: "destroyAdminAdherent",
      getReceivedMessages: "fetchReceivedMessages",
      getUnreadMessagesCount: "fetchUnreadMessagesCount",
      getNewDemandeAttestationCount: "fetchNewDemandeAttestationCount",
      getNewDemandeOuverturesCount: "fetchNewDemandeOuverturesCount",
      getNewPaiementsCount: "fetchNewPaiementsCountCount",
      getNewUsersCount: "fetchNewَAdherents",
      updateEtatConfirmation: "updateAdherentConfirmation",
      fetchAllSelectedIds: "fetchAdminAdherentsIds",
      callPdfGenerator: "callPdfGenerator",
      printShownList: "printShownList",
      send: "sendMessage",
      setCodeForAdherant: "setCodeForAdherant",
    }),
  },
};
</script>
<style>
.mrgin_btm {
  margin-bottom: 15px;
}

.decision {
  color: #009688;
  cursor: pointer;
  text-decoration-line: underline;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
  padding: 16px 10px 16px 10px;
  padding-top: 16px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-left: 10px;
}

.decision {
  border-radius: 50%;
  width: 84px;
  height: 84px;
  margin-right: 10px;
}

td {
  width: max-content;
}

.margin_left {
  margin-left: 1%;
}

.decision {
  text-decoration: underline;
  margin-left: 3%;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-add-btn {
  margin-bottom: 20px !important;
}

.action_icons {
  margin: 4px;
  font-size: large;
}

.txt-end {
  text-align: end;
}

.search_input {
  width: 100%;
}
</style>
