<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleCancel"
    okText="Valider"
    @ok="handleOk"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :layout="formLayout"
      id="decision_form"
      name="decision_form"
      class="adherent_form"
      :form="form"
    >
      <a-row>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Décision">
            <a-select
              placeholder="Etat inscription"
              @change="handleDecisionChange"
              v-decorator="[
                'etat',
                {
                  rules: [
                    { required: true, message: 'Décision  est obligatoire!' },
                  ],
                },
              ]"
            >
              <a-select-option v-for="etat in etatsAdherents" :key="etat.key"
                >{{ etat.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="N° Inscription">
            <a-input
              :disabled="isNotAccepted"
              v-decorator="[
                'matricule',
                {
                  rules: [
                    {
                      required: isAccepted,
                      message: 'N° Inscription est obligatoire!',
                    },
                    { validator: this.validateMatricule },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Date commission">
            <a-date-picker
              :format="dateFormat"
              :disabledDate="disabledFutureDates"
              :showToday="false"
              style="width:100%"
              v-decorator="['dt_comission']"
            />
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Justification">
            <a-textarea
              v-decorator="[
                'justification_decision',
                {
                  rules: [
                    {
                      required: isRefusedOrEnAttente,
                      message: 'Justification est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleOk"
        >Valider</a-button
      >
    </template>
  </a-modal>
</template>
<script>
import { mapActions, mapState } from "vuex";
import _debounce from "lodash-es/debounce";

export default {
  name: "AdherentDecision",
  mounted() {
    this.fillTheForm();
  },
  data() {
    this.validateMatricule = _debounce(this.validateMatricule, 700);
    return {
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      },
      form: this.$form.createForm(this),
      decision: this.get(this.user, "etat"),
    };
  },
  props: {
    processing: { type: Boolean, default: () => false },
    visible: { type: Boolean, default: () => false },
    user: { type: Object },
  },
  computed: {
    ...mapState({
      etatsAdherents: (state) => state.adherents.etatsAdherents,
    }),
    title() {
      return `${this.get(this.user, "full_name")} (${this.dispalyEtat()})`;
    },
    isRefusedOrEnAttente() {
      return (
        this.isUserEnAttente(this.decision) || this.isUserRefuser(this.decision)
      );
    },
    isAccepted() {
      return this.isUserAccepter(this.decision);
    },
    isNotAccepted() {
      return !this.isUserAccepter(this.decision);
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("submit");
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    dispalyEtat() {
      return this.user ? this.etatsAdherents[this.user.etat].value : "";
    },
    fillTheForm() {
      if (this.user) {
        this.user.dt_comission = this.momentDate(
          this.get(this, "user.dt_comission")
        );
        this.$nextTick(() => this.form.setFieldsValue(this.user));
      }
    },
    validateMatricule(rule, value, callback) {
      if (!value) {
        callback();
        return;
      }
      let params = { matricule: value };
      this.startProcessing();
      if (this.get(this, "user.id")) {
        params.user_id = this.user.id;
      }

      let _that = this;
      this.checkMatriculeExistance(params)
        .then(() => callback())
        .catch((error) => {
          if (
            this.get(error, "response.status") === this.HTTP_VALIDATION_ERROR
         ) {
            _that.form.setFields({
              matricule: {
                value: value,
                errors: [new Error("Matricule est deja utilisé")],
              },
            });
            callback("Matricule est deja utilisé");
          }
        })
        .finally(() => this.stopProcessing());
    },
    handleDecisionChange(value) {
      this.decision = value;
      this.$nextTick(() =>
        this.form.validateFields(["justification_decision", "matricule"], {
          force: true,
        })
      );
    },
    ...mapActions({
      checkMatriculeExistance: "checkMatriculeExistance",
      suggestMatricule: "suggestMatricule",
    }),
  },
  watch: {
    decision: function(newValue, oldValue) {
      if (this.isUserAccepter(newValue) && !this.user.matricule) {
        this.startProcessing();
        this.suggestMatricule({user_id: this.user.id})
          .then(r => this.form.setFieldsValue({matricule: r.data.matricule}))
          .finally(() => this.stopProcessing());
      }
    },
  },
};
</script>
